import {
  AllSupplierSettings,
  InputUpdateCoreSupplierSettings,
  ResultSystemConfiguration,
  SupplierSettings,
  WillCallLocation
} from '../../api/supplier-api-interface-supplier';
import { clone, compare } from '../../../webmodule-common/other/clone';
import { DevelopmentError } from '../../../webmodule-common/other/development-error';
import { getApiFactory } from '../../api/api-injector';

export class SupplierSettingsManager {
  public systemSettings?: ResultSystemConfiguration;

  protected _settingsBackup?: AllSupplierSettings;
  protected _allSettings?: AllSupplierSettings;

  protected _updateInput?: InputUpdateCoreSupplierSettings;

  public get updateInput(): InputUpdateCoreSupplierSettings {
    if (!this._updateInput) throw new DevelopmentError('must load settings first ');
    return this._updateInput;
  }

  protected _supplierSettings?: SupplierSettings;

  public get supplierSettings(): SupplierSettings {
    if (!this._updateInput) throw new DevelopmentError('must load settings first ');
    return this._updateInput.settings;
  }

  public get originalSettings(): SupplierSettings {
    if (!this._allSettings) throw new DevelopmentError('must load settings first ');
    return this._allSettings.settings;
  }

  public async getQuoteItemConfigForSuplier() {
    if (!(await this.needsSystemConfiguration())) {
      return null;
    }

    return this._supplierSettings!.quoteItemTableConfiguration?.filter(x => !x.systemDefault);
  }

  clear() {
    this._allSettings = undefined;
    this._updateInput = undefined;
  }

  public checkLoaded() {
    if (!this._allSettings) throw new DevelopmentError('Settings Api has not been called');
  }

  public async needsSettings(refresh?: boolean): Promise<boolean> {
    if (!this._allSettings || refresh) {
      this._settingsBackup = undefined;
      const settings = await getApiFactory().supplier().getCoreSupplierSettingsEntry();
      if (settings) this._allSettings = settings;
      else return false;
      this._settingsBackup = clone(settings);
      this._updateInput = this.makeInputObject();
    }
    return true;
  }

  public async needsSystemConfiguration(): Promise<boolean> {
    if (!this.systemSettings?.supplierQuoteItemConfigurations) {
      const result = await getApiFactory().supplier().getSystemConfiguration({});
      if (!result) {
        return false;
      }

      this.systemSettings = result;
    } else {
      return false;
    }
    return true;
  }

  public async saveSettings(): Promise<boolean> {
    if (!this._allSettings || !this._updateInput) throw new DevelopmentError('settings have not been loaded');
    if (compare(this._updateInput.pricingRules, this._settingsBackup?.pricingRules))
      this._updateInput.pricingRules = null;

    if (compare(this._updateInput.purchaseOrderRecipients, this._settingsBackup?.purchaseOrderRecipients))
      this._updateInput.purchaseOrderRecipients = null;

    if (!this.changed()) {
      await this.needsSettings(true);
      return true;
    } else {
      const result = await getApiFactory().supplier().updateCoreSupplierSettingsEntry(this._updateInput);
      if (!result) return false;
      this._allSettings = result;
      this._updateInput = this.makeInputObject();
      return true;
    }
  }

  public async addWillCallLocation(location: WillCallLocation, save?: boolean): Promise<boolean> {
    if (!this.supplierSettings.willCallLocations) this.supplierSettings.willCallLocations = [];

    this.supplierSettings.willCallLocations.push(location);
    if (save) {
      return await this.saveSettings();
    }

    return true;
  }

  public async removeWillCallLocation(location: WillCallLocation, save?: boolean): Promise<boolean> {
    const index = this.supplierSettings.willCallLocations?.findIndex(x => x.id === location.id);

    if (index === undefined || index === -1 || !this.supplierSettings.willCallLocations) {
      return false;
    }

    this.supplierSettings.willCallLocations.splice(index, 1);

    if (this.supplierSettings.willCallLocations.length === 0) this.supplierSettings.willCallLocations = null;

    if (save) {
      return await this.saveSettings();
    }

    return true;
  }

  public async updateWillCallLocation(location: WillCallLocation, save?: boolean): Promise<boolean> {
    const index = this.supplierSettings.willCallLocations?.findIndex(x => x.id === location.id);

    if (index === undefined || index === -1 || !this.supplierSettings.willCallLocations) {
      return false;
    }

    this.supplierSettings.willCallLocations[index] = location;
    if (save) {
      return await this.saveSettings();
    }

    return true;
  }

  public changed(): boolean {
    return (
      this._updateInput?.base64LoginImage !== null ||
      this._updateInput?.base64NegativeLogo !== null ||
      this._updateInput?.base64PositiveLogo !== null ||
      this._updateInput?.base64TermsAndConditions !== null ||
      this._updateInput?.base64TermsOfUse !== null ||
      this._updateInput?.base64ResourceLogo !== null ||
      !compare(this._updateInput?.pricingRules, this._allSettings?.pricingRules) ||
      !compare(this._updateInput.purchaseOrderRecipients, this._allSettings?.purchaseOrderRecipients) ||
      !compare(this._updateInput?.settings, this._allSettings?.settings)
    );
  }

  public reset() {
    this._updateInput = this.makeInputObject();
  }

  public async deleteResourceLogo(): Promise<boolean> {
    if (!this._allSettings) throw new DevelopmentError('settings have not been loaded');

    const result = await getApiFactory().supplier().deleteSupplierImages({
      settings: this._allSettings.settings,
      deleteNegativeLogo: false,
      deletePositiveLogo: false,
      deleteResourceLogo: true
    });
    if (result) {
      this._allSettings.settings = result.supplierSettings;
      this._updateInput = this.makeInputObject();
      return true;
    }
    return false;
  }

  protected makeInputObject(): InputUpdateCoreSupplierSettings | undefined {
    if (!this._allSettings) return undefined;
    const val = {
      base64LoginImage: null,
      base64NegativeLogo: null,
      base64PositiveLogo: null,
      base64ResourceLogo: null,
      base64TermsAndConditions: null,
      base64TermsOfUse: null,
      loginImageFileName: null,
      negativeLogoFileName: null,
      positiveLogoFileName: null,
      resourceLogoFileName: null,
      resourceLogoAltText: null,
      resourceUrl: null,
      pricingRules: clone(this._allSettings.pricingRules),
      purchaseOrderRecipients: clone(this._allSettings.purchaseOrderRecipients),
      settings: clone(this._allSettings.settings)
    };
    this._updateInput = val;
    return val;
  }
}
