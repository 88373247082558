export function equals(a: any, ...b: any[]): boolean {
  return b.some(x => a === x);
}

export function notEquals(a: any, ...b: any[]): boolean {
  return b.every(x => a !== x);
}

export function allSame(...values: any[]): boolean {
  if (values.length === 0) return true;
  return values.every((v, _i, a) => v === a[0]);
}
