import { autoElement } from '../../../webmodule-common/components/src/webmodule-components';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { fileIsImageFile } from '../../../webmodule-common/other/ui/file-helper-functions';
import { fireQuickSuccessToast } from '../../../webmodule-common/other/toast-away';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { getAbsoluteVirtualFilePath } from '../../api/blob-helper';
import { getAssetCdnPath } from '../../../webmodule-common/other/common/assets';
import { getSettingsManager } from '../../supplier/common/supplier-settings';
import { html } from 'lit';
import { isAutoSaving } from '../../../webmodule-common/other/save-workflow';
import { isEmptyOrSpace } from '../../../webmodule-common/other/webmodule-common';
import { isSomething } from '../../../webmodule-common/other/general/nothing';
import { PageControlTabWithIndependantSaving } from '../../../webmodule-common/other/ui/data-entry-screen-base';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { tlangMarkdown } from '../../../webmodule-common/other/general/markdown';
import type { PromiseTemplate, Snippet } from '../../../webmodule-common/other/ui/events';
import type { SupplierSettings } from '../../api/supplier-api-interface-supplier';
import type { SupplierSettingsManager } from '../../supplier/common/supplier-settings-manager';

@autoElement()
export class SettingsLogoBrandingView extends PageControlTabWithIndependantSaving {
  private settingsManager: SupplierSettingsManager = getSettingsManager();
  private dataTracker: DataTracker;
  private dataBinding: DataBinding;
  private newPositiveLogo?: File;
  private newNegativeLogo?: File;
  private positiveLogoName = 'supplier-positive-logo';
  private negativeLogoName = 'supplier-negative-logo';
  // resources
  private newResourceLogo?: File;
  private resourceLogoName = 'resource-logo';

  constructor() {
    super();
    this.pageFragment = 'branding';

    this.dataBinding = new DataBinding(this.ui, this.elementId, input => {
      return `${input}-${this.elementId}`;
    });
    this.dataTracker = new DataTracker(this.dataBinding);

    this.dataTracker.addImageLink(
      this.positiveLogoName,
      () =>
        getAbsoluteVirtualFilePath(
          this.settings.positiveLogoVirtualPath,
          getAssetCdnPath('images/sample-logo-colour.png')
        ),
      value => {
        this.newPositiveLogo = value;
      }
    );

    this.dataTracker.addImageLink(
      this.negativeLogoName,
      () =>
        getAbsoluteVirtualFilePath(
          this.settings.negativeLogoVirtualPath,
          getAssetCdnPath('./assets/images/sample-logo-colour.png')
        ),
      value => {
        this.newNegativeLogo = value;
      }
    );

    this.dataTracker.addImageLink(
      this.resourceLogoName,
      () => getAbsoluteVirtualFilePath(this.settings.resourceLogoVirtualPath, ''),
      value => {
        this.newResourceLogo = value;
      }
    );

    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.settings),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('resourceUrl');
    addField('resourceLogoAltText');
  }

  get settings(): SupplierSettings {
    return this.settingsManager.supplierSettings;
  }

  public allowDeletePage(): boolean {
    return false;
  }

  getValidationErrors(): string[] {
    const errors: string[] = [];

    // throw image upload error only when a file has been provided, but it isn't a valid image
    if (this.newPositiveLogo && !fileIsImageFile(this.newPositiveLogo)) {
      errors.push(tlang`Please provide a valid Color Logo`);
    }
    // throw image upload error only when a file has been provided, but it isn't a valid image
    if (this.newNegativeLogo && !fileIsImageFile(this.newNegativeLogo)) {
      errors.push(tlang`Please provide a valid White Logo`);
    }
    const isResourceURLEmpty = isEmptyOrSpace(this.settings.resourceUrl);
    const isResourceDescriptionEmpty = isEmptyOrSpace(this.settings.resourceLogoAltText);
    // if we only have one of the two compulsory resource fields filled out, then log the corresponding validation error
    if (isResourceURLEmpty !== isResourceDescriptionEmpty) {
      if (isResourceURLEmpty) {
        errors.push(tlang`Please provide a Resource URL`);
      }
      if (isResourceDescriptionEmpty) {
        errors.push(tlang`Please provide a Resource label`);
      }
    }
    // throw image upload error only when a file has been provided, but it isn't a valid image
    if (this.newResourceLogo && !fileIsImageFile(this.newResourceLogo)) {
      errors.push(tlang`Please provide a valid Resource Logo`);
    }

    return errors;
  }

  async prepareForSave(): Promise<void> {
    this.dataTracker.applyChangeToValue();
  }

  public internalDataChanged(): boolean {
    return (
      this.settingsManager.changed() || isSomething(this.newNegativeLogo, this.newPositiveLogo, this.newResourceLogo)
    );
  }

  async onEnter(): Promise<void> {
    await this.settingsManager.needsSettings(true);
    this.requestUpdate();
  }

  protected getCaption(): Snippet {
    return tlang`Branding`;
  }

  protected revertChanges(): Promise<boolean> {
    this.newNegativeLogo = undefined;
    this.newNegativeLogo = undefined;
    this.newResourceLogo = undefined;
    this.settingsManager.reset();
    return Promise.resolve(true);
  }

  protected async internalSaveData(): Promise<boolean> {
    // attempt to upload a logo from the given element and get its uploaded file path
    const newPositiveLogo = await this.dataBinding.createVirtualFile(this.newPositiveLogo);
    const newNegativeLogo = await this.dataBinding.createVirtualFile(this.newNegativeLogo);
    const newResoucesImage = await this.dataBinding.createVirtualFile(this.newResourceLogo);

    this.settingsManager.updateInput.base64PositiveLogo = newPositiveLogo?.content ?? null;
    this.settingsManager.updateInput.base64NegativeLogo = newNegativeLogo?.content ?? null;
    this.settingsManager.updateInput.positiveLogoFileName = newPositiveLogo
      ? `positiveBrandingLogo${newPositiveLogo.extension}`
      : null;
    this.settingsManager.updateInput.negativeLogoFileName = newNegativeLogo
      ? `negativeBrandingLogo${newNegativeLogo.extension}`
      : null;

    this.settingsManager.updateInput.base64ResourceLogo = newResoucesImage?.content ?? null;
    this.settingsManager.updateInput.resourceLogoFileName = newResoucesImage
      ? `resourcesLogo${newResoucesImage.extension}`
      : null;

    const result = await this.settingsManager.saveSettings();
    if (result) {
      if (!isAutoSaving()) fireQuickSuccessToast(tlang`Branding saved`);
      this.dataTracker.removeFilesFromEditors();
      this.requestUpdate();
      return true;
    }
    return false;
  }

  protected async bodyTemplate(): PromiseTemplate {
    const form = new FormInputAssistant(this.dataTracker, false, true);
    const brandingMsg = tlangMarkdown`${'ref:FranchiseeBrandingView:uploadMsg:1'}
Please upload your business logo<br>
This will be incorporated into documents sent to !!client!! and !!franchisee!!, such as !!estimate!!/!!invoice!!/!!purchase-order!!.

Choose a logo to appear at the top of PDFs and reports you print or send.<br>
The minimum permitted size is **800(W) x 400(H)** pixels.<br>
The ideal size of your logo is **1600(W) x 800(H)** pixels.<br>
The image must be **300DPI** in quality and in the **jpg** format on a white background.<br>
If the aspect ratio of your logo is different, it will be scaled down in proportion to fit the template constraints.
`;

    return html` <div>
      <form id="CompanyBrandingForm" class="form-three-col">
        <h2>${tlang`Specifications`}</h2>
        <p>${brandingMsg}</p>
        <div class="row branding-wrapper">
          <div class="col-sm-4 form-column">
            <h4 class="section-header branding-image-header">${tlang`Colour Logo`}</h4>
            <div class="branding-image-description">
              ${tlang`This logo appears on the top left hand corner of your masthead.`}
            </div>
            ${form.imagePicker(this.positiveLogoName, tlang`Color Logo`)}
          </div>
          <div class="col-sm-4 form-column">
            <h4 class="section-header branding-image-header">${tlang`White Logo`}</h4>
            <div class="branding-image-description">
              ${tlang`This logo appears on printed reports and on the right hand side in the Dealer Portal Footer.`}
            </div>
            ${form.imagePicker(this.negativeLogoName, tlang`White Logo`)}
          </div>
          <div class="col-sm-4 form-column">
            <h4 class="section-header branding-image-header">${tlang`Resource Link`}</h4>
            <div class="branding-image-description">
              ${tlang`This logo appears on the right hand side in the Dealer Portal Footer, linking to the URL provided below.`}
            </div>
            ${form.imagePicker(this.resourceLogoName, tlang`Resources Logo`, {
              onDeleteEvent: async () => await this.deleteResourceLogo()
            })}
            ${form.text1('resourceUrl', 'URL')} ${form.text1('resourceLogoAltText', 'Resource Alt text')}
          </div>
        </div>
      </form>
    </div>`;
  }

  protected async deleteResourceLogo() {
    if (!isEmptyOrSpace(this.settings.resourceLogoVirtualPath)) {
      const result = await this.settingsManager.deleteResourceLogo();
      if (result) {
        fireQuickSuccessToast(tlang`Logo deleted`);
      }
    }
    const binder = this.dataTracker.getBinder(this.resourceLogoName);
    if (binder) {
      binder.editorBinder.setValue('');
      this.requestUpdate();
    }
  }
}
