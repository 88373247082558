// eslint-disable-next-line import/named
import { DataBinding } from '../databinding/databinding';
import { DataTracker } from '../databinding/data-tracker';

import { html, TemplateResult } from 'lit';

import { when } from 'lit/directives/when.js';

import { map } from 'lit/directives/map.js';
import { WebmoduleFormInputEvent } from './input-type';
import { FormInputSelectValue } from './form-input-types';
import { getToolTip } from '../../tooltip';

export function formSelect(
  fieldName: string,
  placeHolder: string,
  title: string | undefined,
  values: string | FormInputSelectValue[],
  dataBinding: DataBinding,
  dataTracker: DataTracker,
  readOnly = false,
  required = false,
  onChangeEvent?: WebmoduleFormInputEvent,
  immediateBindingUpdate?: boolean,
  slot?: string,
  slotted?: TemplateResult,
  classes?: string,
  toolTip?: string | TemplateResult,
  tooltipMaxSize?: string,
  multiple?: boolean
): TemplateResult {
  const internalOptions = values ?? [];

  const optionsArray: FormInputSelectValue[] =
    typeof internalOptions === 'string' ? JSON.parse(internalOptions) : internalOptions;

  const eventOnChanged = (e: Event) => {
    if (immediateBindingUpdate ?? true) {
      dataTracker.getBinder(fieldName)?.applyChangeToValue();
    }
    onChangeEvent?.(e);
  };
  const value1 = dataTracker.getObjectValue(fieldName);
  const isArray = Array.isArray(value1);
  const value = isArray ? value1 : (value1?.toString() ?? '');
  return html` <webmodule-select
    class="webmodule-control-left-label ${classes ?? ''}"
    id=${dataBinding.field(fieldName)}
    placeholder=${placeHolder}
    ?disabled=${readOnly}
    ?required=${required}
    ?multiple=${multiple || isArray}
    .value=${value ?? ''}
    size="small"
    slot=${slot}
    @webmodule-change=${eventOnChanged}
    hoist
  >
    ${slotted}
    ${when(
      title,
      () => html`
        <span slot="label"> ${title}${toolTip ? getToolTip(toolTip, { maxSize: tooltipMaxSize }) : ''} </span>
      `
    )}
    ${map(optionsArray, x => html` <webmodule-option value="${x.value}">${x.text}</webmodule-option>`)}
  </webmodule-select>`;
}
